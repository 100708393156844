<template>
    <iframe v-if="iframeSrc" class="IntegrationIframe" :src="iframeSrc"></iframe>
</template>

<script>
  import { EventBus } from '@/main'
  let currentCustomerId = '';
  let currentUserId = '652502';
  export default {
    name: "Integration",

    mounted() {
      // Workato.handleNavigation({
      //   onWorkatoNavigation: ({embeddingUrl, urlReplaced, preventVendorUrlChange}) => {
      //     // Preventing URL change as it will be done by Vue in `$router.replace` and `$router.push` method calls below
      //     preventVendorUrlChange();

      //     if (urlReplaced) {
      //       this.$router.replace(embeddingUrl);
      //     } else {
      //       this.$router.push(embeddingUrl);
      //     }
      //   },
      //   onVendorNavigation(event) {
      //     if (event.reason === 'link') {
      //       // Preventing URL change as it will be done by Vue
      //       event.preventVendorUrlChange();
      //     }
      //     // Also preventing Workato URL change as it will be done in `beforeRouteUpdate` method after Vue
      //     // will change current URL
      //     event.preventWorkatoUrlChange();
      //   }
      // });
    },

    async beforeRouteEnter(to, from, next) {
      currentCustomerId = from.query.WORKATO_ID;
      const token = await getToken(currentCustomerId, currentUserId);
      console.log(from.query.WORKATO_ID);

      to.query.WORKATO_ID = from.query.WORKATO_ID;
      next(vm => vm.updateIframeSrc(to.fullPath, token), {path: to.path, query: to.query});
    },

    async beforeRouteUpdate(to, from, next) {
      if (Workato.loaded) {
        // Workato.navigateTo(Workato.extractWorkatoUrl(to.fullPath));
        next();
      } else {
        // Removing currently loading iframe
        this.updateIframeSrc(null);
        // Requesting a token and updating an iframe `src`
        currentCustomerId = from.query.WORKATO_ID ? from.query.WORKATO_ID : currentCustomerId;
        to.query.WORKATO_ID = from.query.WORKATO_ID;
        if (from.query.WORKATO_ID === '717777') {
          currentUserId = '147089';
        } else {
          currentUserId = '652502';
        }
        this.updateIframeSrc(to.fullPath, await getToken(currentCustomerId, currentUserId));
        next();
      }
    },

    destroyed() {
      Workato.disableNavigationHandling();
    },

    data() {
      return {
        iframeSrc: null
      }
    },

    methods: {
      updateIframeSrc(embeddingUrl, token) {
        this.iframeSrc = embeddingUrl ?
          Workato.generateIFrameUrl(token, Workato.extractWorkatoUrl(embeddingUrl)) :
          null;
      }
    }
  };

  async function getToken(customerId, userId) {
    return fetch(`https://y1jo0uu3sk.execute-api.us-east-1.amazonaws.com/dev/workato-jwt?customer=${customerId}&user=${userId}`).then(res => res.json());
  }
</script>

<style lang="scss">
  .IntegrationIframe {
    border: none;
    width: 100%;
    height: 100%;
  }
</style>
