<template>
  <div class="AppHeader">
    <MainMenu/>
    <router-link class="AppHeader__logo" to="/"></router-link>
    <button @click="changeCustomer('652516')">Tax Partner</button>
    <button @click="changeCustomer('687300')">Tax Customer</button>
    <button @click="changeCustomer('677246')">Legal Customer</button>
    <Search/>
    <!-- <UserMenu/> -->
  </div>
</template>

<script>
  import Search from '@/components/Search'
  import MainMenu from "@/components/MainMenu";
  // import UserMenu from "@/components/UserMenu";
  import { EventBus } from '@/main.js'

  export default {
    name: 'AppHeader',
    components: { MainMenu, Search },
    methods: {
      changeCustomer(customerId){
        EventBus.$emit('changeCustomerId', customerId)
      }
    }
  }
</script>

<style lang="scss">
  .AppHeader {
    background: #404040;
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 50px;
    flex: 0 0 auto;

    &__logo {
      margin-left: 30px;
      width: 225px;
      margin-right: auto;
      height: 30px;
      background: url("../assets/tr_logo.png") center / 100% no-repeat;
      flex: 0 0 auto;
    }
  }
</style>
