<template>
  <div class="SidePanel">
    <!-- <div class="SidePanel__header">
      <WorkatoLink class="button button_link button_block" href="/recipes/new">
        <i class="fas fa-plus"></i>
        New recipe
      </WorkatoLink>
    </div> -->
    <!-- <div class="SidePanel__separator"></div> -->
    <div class="SidePanel__content">
      <WorkatoLink class="SidePanel__item" activeClass="SidePanel__item_active" href="/dashboard/main"
        :activeOn="['/dashboard*']">
        Dashboard
      </WorkatoLink>
      <WorkatoLink class="SidePanel__item" activeClass="SidePanel__item_active" href="/"
        :activeOn="['/recipes*']">
        Recipes
      </WorkatoLink>
      <WorkatoLink class="SidePanel__item" activeClass="SidePanel__item_active" href="/browse/recipes"
        :activeOn="['/browse/*', '/custom_adapters/*']">
        Community
      </WorkatoLink>
      <WorkatoLink v-if="partner" class="SidePanel__item" activeClass="SidePanel__item_active" href="/export_manifests"
        :activeOn="['/export_manifests*']">
        Partner Recipe Management
      </WorkatoLink>
      <WorkatoLink class="SidePanel__item" activeClass="SidePanel__item_active" href="/browse/recipes"
        :activeOn="['/browse/*', '/custom_adapters/*']">
        Community
      </WorkatoLink>
    </div>
  </div>
</template>

<script>
  import WorkatoLink from "@/components/WorkatoLink";
  import { EventBus } from '@/main'

  export default {
    name: "SidePanel",
    components: { WorkatoLink },
    mounted() {
      EventBus.$on('changeCustomerId', (customerId) => {
            // if customerId == kayla's user id, we want to do a Tax Partner Account
            if(customerId === '652516'){
              this.partner = true;
            }
            else {
              this.partner = false;
            }
          });
    },
    data() {
      return {
        partner: false
      }
    }
  }
</script>

<style lang="scss">
  .SidePanel {
    background: #D0D0D0;
    height: 100%;

    &__header {
      margin: 25px 47px 25px 25px;
    }

    &__separator {
      display: block;
      content: "";
      height: 3px;
      background: #fff;
      margin: 25px 47px 25px 15px;
    }

    &__item {
      display: block;
      border-left: 4px solid transparent;
      padding: 8px 45px 8px;
      font-size: 14px;

      &,
      &:link,
      &:visited,
      &:hover,
      &:active {
        text-decoration: none;
      }

      &,
      &:link,
      &:visited,
      &:hover,
      &:active {
        color: #404040;
      }

      &_active {
        border-left-color: #AFAFAF;
        background: #DADFE3;
        font-weight: bold;
      }
    }
  }
</style>
